import { ElementArgs, TablesOptions } from '../../models/special-document/ElementArgs'
import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { T } from '../../utils/i18n-config'
import { BasicResponse } from './ThreeStatesTableRepository'

export interface IReturnId {
  id: number
}

export interface ResponseUpDown {
  success: boolean,
  id: number,
  swapId: number|null
}

export class ElementRepository{
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  //private readonly updateApiUrl: string = getUrl('element_api/update')
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  private async fetchJSON(url: string, options?: RequestInit): Promise<any> {
    const response = await this.fetcher(url, options);
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  async createElement(element: ElementArgs): Promise<number> {
    const engagementIdModified = element.engagement_id ?? 'None';
    const apiUrl = `${getUrl("elements_api/create_element")}/${engagementIdModified}`
    const data:IReturnId = await this.fetchJSON(apiUrl, {
      method: 'POST',
      headers: this._jsonContentType,
      body: JSON.stringify(element)
    })
    if("success" in data && !data.success){
      throw new Error(T("Error creating element"))
    }
    return data.id
  }
  
  async saveElement(renderingMode: string, element: ElementArgs): Promise<boolean> {
    const engagementIdModified = element.engagement_id ?? 'None';
    const apiUrl = `${getUrl("elements_api/save_element")}/${engagementIdModified}/${renderingMode}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(element)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: BasicResponse = await response.json()
      if(!data.success){
        throw new Error(T("Error saving element"))
      }
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }

  }

  async getElementsByDocumentSpecialId(engagementId: number|null, documentId: number, renderingMode:string): Promise<ElementArgs[]> {
    const engagementIdModified = engagementId ?? 'None';
    const apiUrl = `${getUrl("elements_api/get_elements_by_special_document_id")}/${engagementIdModified}/${documentId}/${renderingMode}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: ElementArgs[] | BasicResponse = await response.json()
      if("success" in data && !data.success){
        throw new Error(T("Error getting elements"))
      }
      return data as ElementArgs[]
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async deleteElement(engagementId: number|null, documentId: number, elementId: number): Promise<boolean> {
    const engagementIdModified = engagementId ?? 'None';
    const apiUrl = `${getUrl("elements_api/delete_element")}/${engagementIdModified}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: BasicResponse = await response.json()
      if(!data.success){
        throw new Error(T("Error deleting element"))
      }
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  async upElement(engagementId: number|null, documentId: number, elementId: number): Promise<ResponseUpDown> {
    const engagementIdModified = engagementId ?? 'None';
    const apiUrl = `${getUrl("elements_api/up_element")}/${engagementIdModified}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return {success: false, id: 0, swapId: 0}
      }
      const data: ResponseUpDown = await response.json()
      if(!data.success){
        return {success: false, id: 0, swapId: 0}
      }
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return {success: false, id: 0, swapId: 0}
    }
  }

  async downElement(engagementId: number|null, documentId: number, elementId: number): Promise<ResponseUpDown> {
    const engagementIdModified = engagementId ?? 'None';
    const apiUrl = `${getUrl("elements_api/down_element")}/${engagementIdModified}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return {success: false, id: 0, swapId: 0}
      }
      const data: ResponseUpDown = await response.json()
      if(!data.success){
        throw new Error(T("Error updating position element"))
      }
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return {success: false, id: 0, swapId: 0}
    }
  }

  async getRedirectUrl(engagementId: number|null, documentId: number, elementId: number, context: string): Promise<string> {
    const engagementIdModified = engagementId ?? 'None';
    const baseUrl = getUrl("elements_api/get_contextual_ids")
    const apiUrl = `${baseUrl}/${engagementIdModified}/${documentId}/${elementId}?context=${context}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return ""
      }

      const data = await response.json()
      return data.url as string
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return ""
    }
  }

  async getExtractedValues(
    engagementId: number, documentId: number, elementId: number, attachmentName: string, informationList: string[]
  ): Promise<{ success: boolean, information?: string}> {
    const apiUrl = `${getUrl("elements_api/get_extracted_values")}/${engagementId}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({attachmentName, informationList})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return {success: false}
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return {success: false}
    }
  }

  async getGlobalVariable(
    engagementId: number, elementId: number, variable: string, reference?: string,
  ): Promise<{success: boolean, variable: string[]}> {
    const checkedReference = reference ?? "None"
    const baseUrl = getUrl("elements_api/get_global_variable")
    const apiUrl = `${baseUrl}/${engagementId}/${elementId}?reference=${checkedReference}&variable=${variable}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false, variable: [] }
      }

      const data = await response.json()
      if (data.success) return data
      return { success: false, variable: [] }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, variable: [] }
    }
  }
}

