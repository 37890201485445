import React from 'react';
import { ElementType } from "../models/special-document/ElementType";
import { ElementParagraph } from "../models/special-document/ElementParagraph";
import { ConditionalParagraphElementWithSelector } from "../models/special-document/ConditionalParagraphElementWithSelector";
import { ConditionalParagraphElementWithGlobalVariable } from "../models/special-document/ConditionalParagraphElementWithGlobalVariable";
import { ThreeStatesTableElement } from "../models/special-document/ThreeStatesTableElement";
import { CustomerRiskClassificationElement } from "../models/special-document/CustomerRiskClassificationElement";
import { ManagementIntegrityElement } from "../models/special-document/ManagementIntegrityElement";
import { IndependenceFormatElement } from "../models/special-document/IndependenceFormatElement";
import { OfacLinkElement } from "../models/special-document/OfacLinkElement";
import { ConditionalParagraphOfacElement } from "../models/special-document/ConditionalParagraphOfacElement";
import { MathFormulaDisplayValueElement } from "../models/special-document/MathFormulaDisplayValueElement";
import { ConditionalMathFormulaElementMessage } from "../models/special-document/ConditionalMathFormulaElementMessage";
import { VarianceAnalysisNotesElement } from "../models/special-document/VarianceAnalysisNotesElement";
import { MaterialityElement } from "../models/special-document/MaterialityElement";
import { AttachDocumentElement } from "../models/special-document/AttachDocumentElement";
import { FindingsElement } from "../models/special-document/FindingsElement";
import { FindingsEvaluationElement } from "../models/special-document/FindingsEvaluationElement";
import { ElementOfacResults } from '../models/special-document/ElementOfacResults';
import { ProbabilityAnalysisElement } from '../models/special-document/ProbabilityAnalysisElement';
import { RedirectionButtonElement } from '../models/special-document/RedirectionButtonElement';
import { QuestionsTableElement } from '../models/special-document/QuestionsTableElement';
import { InformationExtractorElement } from '../models/special-document/InformationExtractorElement';

import {SubstantiveTestBankConciliationsElement} from '../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import { BankConciliationsQuestionElement } from '../models/substantive-tests/BankConciliationsQuestionElement';
import { IElementBase } from '../models/special-document/ElementBase';
import ParagraphViewMode from '../components/special-document/ParagraphViewMode';
import ParagraphEditMode from '../components/special-document/ParagraphEditMode';
import ShowOfacResultsEditMode from '../components/special-document/ShowOfacResultsEditMode';
import ShowOfacResultsViewMode from '../components/special-document/ShowOfacResultsViewMode';
import ConditionalParagraphWithSelectorEditMode from '../components/special-document/ConditionalParagraphWithSelectorEditMode';
import ConditionalParagraphWithSelectorViewMode from '../components/special-document/ConditionalParagraphWithSelectorViewMode';
import ConditionalParagraphWithVariableEditMode from '../components/special-document/ConditionalParagraphWithVariableEditMode';
import ConditionalParagraphWithVariableViewMode from '../components/special-document/ConditionalParagraphWithVariableViewMode';
import ThreeStatesTableEditMode from '../components/special-document/ThreeStatesTableEditMode';
import ThreeStatesTableViewMode from '../components/special-document/ThreeStatesTableViewMode';
import IndependenceFormatEditMode from '../components/special-document/IndependenceFormatEditMode';
import IndependenceFormatViewMode from '../components/special-document/IndependenceFormatViewMode';
import ConditionalParagraphOfacViewMode from '../components/special-document/ConditionalParagraphOfacViewMode';
import ConditionalParagraphOfacEditMode from '../components/special-document/ConditionalParagraphOfacEditMode';
import VarianceAnalysisNotesEditMode from '../components/special-document/VarianceAnalysisNotesEditMode';
import VarianceAnalysisNotesViewMode from '../components/special-document/VarianceAnalysisNotesViewMode';
import AttachDocumentEditMode from '../components/special-document/AttachDocumentEditMode';
import AttachDocumentViewMode from '../components/special-document/AttachDocumentViewMode';
import FindingsContainerEditMode from '../components/special-document/FindingsContainerEditMode';
import FindingsContainerViewMode from '../components/special-document/FindingsContainerViewMode';
import FindingsEvaluationEditMode from '../components/special-document/FindingsEvaluationEditMode';
import FindingsEvaluationViewMode from '../components/special-document/FindingsEvaluationViewMode';
import ProbabilityAnalysisEditMode from '../components/special-document/ProbabilityAnalysisEditMode';
import ProbabilityAnalysisViewMode from '../components/special-document/ProbabilityAnalysisViewMode';
import RedirectionButtonViewMode from '../components/special-document/RedirectionButtonViewMode';
import RedirectionButtonEditMode from '../components/special-document/RedirectionButtonEditMode';
import QuestionsTableEditMode from '../components/special-document/QuestionsTableEditMode';
import QuestionsTableViewMode from '../components/special-document/QuestionsTableViewMode';
import InformationExtractorEditMode from '../components/special-document/InformationExtractorEditMode';
import InformationExtractorViewMode from '../components/special-document/InformationExtractorViewMode';
import RequiredAccountConfigurationBankConciliationsViewMode from '../components/substantive-tests/RequiredAccountConfigurationBankConciliationsViewMode';
import RequiredAccountConfigurationBankConciliationsEditMode from '../components/substantive-tests/RequiredAccountConfigurationBankConciliationsEditMode';
import QuestionBankConciliationsEditMode from '../components/substantive-tests/QuestionBankConciliationsEditMode';
import QuestionBankConciliationsViewMode from '../components/substantive-tests/QuestionBankConciliationsViewMode';
import TrialBalanceFileBankConciliationsViewMode from '../components/substantive-tests/TrialBalanceFileBankConciliationsViewMode';
import TrialBalanceFileBankConciliationsEditMode from '../components/substantive-tests/TrialBalanceFileBankConciliationsEditMode';
import PrincipalTableBankConciliationsViewMode from '../components/substantive-tests/TablePrincipalBankConciliationsViewMode';
import PrincipalTableBankConciliationsEditMode from '../components/substantive-tests/TablePrincipalBankConciliationsEditMode';
import SamplesBankConciliationsViewMode from '../components/substantive-tests/SamplesBankConciliationsViewMode';
import SamplesBankConciliationsEditMode from '../components/substantive-tests/SamplesBankConciliationsEditMode';
import DifferenceReconcilingItemsViewMode from '../components/substantive-tests/DifferenceReconcilingItemsViewMode';
import DifferenceReconcilingItemsEditMode from '../components/substantive-tests/DifferenceReconcilingItemsEditMode';
import DocumentSampleViewMode from '../components/substantive-tests/DocumentSampleViewMode';
import DocumentSampleEditMode from '../components/substantive-tests/DocumentSampleEditMode';
import { Constants } from '../utils/Constants';


export const template = [
  new ElementType(Constants.PARAGRAPH_ELEMENT_TYPE, "Paragraph", ElementParagraph),
  new ElementType(2, "Conditional Paragraph With Selector", ConditionalParagraphElementWithSelector),
  new ElementType(3, "Conditional Paragraph With Global Variable", ConditionalParagraphElementWithGlobalVariable),
  new ElementType(4, "Three States Table", ThreeStatesTableElement),
  new ElementType(5, "Customer Risk Classification", CustomerRiskClassificationElement),
  new ElementType(6, "Management Integrity", ManagementIntegrityElement),
  new ElementType(7, "Independence Format", IndependenceFormatElement),
  new ElementType(8, "OFAC Link", OfacLinkElement),
  new ElementType(9, "Conditional Paragraph OFAC", ConditionalParagraphOfacElement),
  new ElementType(10, "Math Formula Display Value", MathFormulaDisplayValueElement),
  new ElementType(11, "Conditional Math Formula Message", ConditionalMathFormulaElementMessage),
  new ElementType(Constants.VARIANCE_ANALYSIS_NOTES_TYPE, "Variance Analysis Notes", VarianceAnalysisNotesElement),
  new ElementType(13, "Materiality", MaterialityElement),
  new ElementType(14, "Attach Document", AttachDocumentElement),
  new ElementType(Constants.FINDING_ELEMENT_TYPE, "Recommendations for Management", FindingsElement),
  new ElementType(Constants.FINDING_EVALUATION_ELEMENT_TYPE, "Evaluationspecial-document/ Description", FindingsEvaluationElement),
  new ElementType(17, "OFAC results", ElementOfacResults),
  new ElementType(Constants.PROBABILITY_ANALYSIS_TYPE, "Probablity Analysis", ProbabilityAnalysisElement),
  new ElementType(19, "Redirection Button", RedirectionButtonElement),
  new ElementType(Constants.SUBSTANTIVE_TEST_BANK_CONCILIATIONS_ACCOUNT_CONFIGURATION_TYPE, "Bank Reconciliations Account Configuration", SubstantiveTestBankConciliationsElement),
  new ElementType(21, "Question Bank Conciliations", BankConciliationsQuestionElement),
  new ElementType(22, "Trial Balance File Reconciliations", SubstantiveTestBankConciliationsElement),
  new ElementType(23, "Principal Table Bank Reconciliations", SubstantiveTestBankConciliationsElement),
  new ElementType(24, "Samples Bank Reconciliations", SubstantiveTestBankConciliationsElement),
  new ElementType(25, "Difference Reconciling Items", SubstantiveTestBankConciliationsElement),
  new ElementType(26, "Document Sample", SubstantiveTestBankConciliationsElement),
  new ElementType(27, "Questions Table", QuestionsTableElement),
  new ElementType(28, "Information Extractor", InformationExtractorElement),
];


export interface CreateEditModeComponentProps {
  elementType: number;
  iElement: IElementBase;
  handleDeleteElement: (iElement: IElementBase) => Promise<void>;
  handleUpElement: (iElement: IElementBase) => Promise<void>;
  handleDownElement: (iElement: IElementBase) => Promise<void>;
}

export interface CreateViewModeComponentProps {
  elementType: number;
  iElement: IElementBase;
}

type ElementComponentViewMode = React.ComponentType<{ iElement: IElementBase }>;
type ElementComponentEditionMode = React.ComponentType<{ iElement: IElementBase, handleDeleteElement: (iElement: IElementBase) => Promise<void>,
  handleUpElement: (iElement: IElementBase) => Promise<void>, handleDownElement: (iElement: IElementBase) => Promise<void> }>;

export const elementComponentsViewMode: { [key: number]: ElementComponentViewMode } = {
  1: ParagraphViewMode,
  2: ConditionalParagraphWithSelectorViewMode,
  3: ConditionalParagraphWithVariableViewMode,
  4: ThreeStatesTableViewMode,
  // 5: CustomerRiskClassificationViewMode,
  // 6: ManagementIntegrityViewMode,
  7: IndependenceFormatViewMode,
  // 8: OfacLinkViewMode,
  9: ConditionalParagraphOfacViewMode,
  // 10: MathFormulaDisplayValueViewMode,
  // 11: ConditionalMathFormulaMessageViewMode,
  12: VarianceAnalysisNotesViewMode,
  // 13: MaterialityViewMode,
  14: AttachDocumentViewMode,
  15: FindingsContainerViewMode,
  16: FindingsEvaluationViewMode,
  17: ShowOfacResultsViewMode,
  18: ProbabilityAnalysisViewMode,
  19: RedirectionButtonViewMode,
  20: RequiredAccountConfigurationBankConciliationsViewMode,
  21: QuestionBankConciliationsViewMode,
  22: TrialBalanceFileBankConciliationsViewMode,
  23: PrincipalTableBankConciliationsViewMode,
  24: SamplesBankConciliationsViewMode,
  25: DifferenceReconcilingItemsViewMode,
  26: DocumentSampleViewMode,
  27: QuestionsTableViewMode,
  28: InformationExtractorViewMode,
};

export const elementComponentsEditionMode: { [key: number]: ElementComponentEditionMode } = {
  1: ParagraphEditMode,
  2: ConditionalParagraphWithSelectorEditMode,
  3: ConditionalParagraphWithVariableEditMode,
  4: ThreeStatesTableEditMode,
  // 5: CustomerRiskClassificationEditMode,
  // 6: ManagementIntegrityEditMode,
  7: IndependenceFormatEditMode,
  // 8: OfacLinkEditMode,
  9: ConditionalParagraphOfacEditMode,
  // 10: MathFormulaDisplayValueEditMode,
  // 11: ConditionalMathFormulaMessageEditMode,
  12: VarianceAnalysisNotesEditMode,
  // 13: MaterialityEditMode,
  14: AttachDocumentEditMode,
  15: FindingsContainerEditMode,
  16: FindingsEvaluationEditMode,
  17: ShowOfacResultsEditMode,
  18: ProbabilityAnalysisEditMode,
  19: RedirectionButtonEditMode,
  20: RequiredAccountConfigurationBankConciliationsEditMode,
  21: QuestionBankConciliationsEditMode,
  22: TrialBalanceFileBankConciliationsEditMode,
  23: PrincipalTableBankConciliationsEditMode,
  24: SamplesBankConciliationsEditMode,
  25: DifferenceReconcilingItemsEditMode,
  26: DocumentSampleEditMode,
  27: QuestionsTableEditMode,
  28: InformationExtractorEditMode,
};